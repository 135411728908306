.hamburger {
  float: right;
  font-size: rem-calc(24);
  cursor: pointer;

  @media only screen and (max-width: 40em) {
    padding: 0.4rem;
  }

  .menu-text {
    color: #fff;
  }
}
