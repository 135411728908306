#donateursform {
  // width: 520px;
  font-size: 16px;

  #formDiv {
    display: none;
    background-color: $brooke-oranje;
  }
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

/*-------------------- form -------------------*/
.Brookeform {
  color: $brooke-gray;
  line-height: 140%;
  div {
    /*border: 1px solid #e0e0e0;*/
    padding: 1px;
  }
  .row {
    padding: 0px;
    margin: 0px;
    /*    border: 1px solid #f00;*/
    clear: both;
    display: block;
  }
  label {
    display: inline-block;
  }

  .smallbox {
    width: 50px;
  }
  .mediumbox {
    width: 100px;
  }

  .label {
    clear: left;
    float: left;
    height: 18px;
    margin: 6px 5px 0px 10px;
    padding: 0;
    width: 40%;
    color: $brooke-oranje;
    font-weight: bold;
    text-align: right;
    font-size: 11px;

    &:after {
      clear: both;
    }

    .error {
      color: red;
      display: block;
      font-style: italic;
      font-weight: normal;
      text-align: right;
      width: 250px;
    }
  }

  @media (max-width: 767px) {
    .label {
      float: none;
      text-align: left;
      font-size: 16px;
    }
  }

  .submitBtn {
    color: #ffffff;
    display: block;
    float: right;

    height: 82px;

    position: relative;
    text-align: center;
    text-decoration: none;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    width: 206px;
    background: url("/_css/images/submitBtn.png") no-repeat scroll 0 0 transparent;

    &:hover {
      background-position: left bottom;
    }
  }

  // .doneerBtn {
  //   background: url("/_css/images/doneerBtn.png") no-repeat scroll 0 0 transparent;
  //   &:hover {
  //     background-position: left bottom;
  //   }
  // }

  .label-right {
    text-align: right;
    float: none;
  }

  .singlelineInput {
    border: 1px solid $brooke-gray;
    height: 18px;
    margin: 4px 0;
    padding: 0 2px;
    width: 50%;
  }
  .eenderde {
    width: 23px;
  }

  .tabs {
    border-bottom: 1px solid $brooke-oranje;
    margin: 0 0 5px;
    overflow: hidden;

    li {
      float: left;
      margin: 0 4px 0 0;
      list-style: none;
    }

    a.active {
      background: none repeat scroll 0 0 $brooke-oranje;
      color: #ffffff !important;
    }

    a {
      background: none repeat scroll 0 0 #eeeeee;
      border-radius: 3px 3px 0 0;
      color: #959595;
      display: inline-block;
      margin-right: 1px;
      padding: 6px 10px;
      text-decoration: none;
    }
  }

  .formLegend {
    font-size: 16px;
    padding: 5px 0 20px 0;
    color: $brooke-gray;

    .chapter {
      font-size: 16px;
      background-color: $brooke-oranje;
      padding: 4px 8px 4px 8px;
      color: #ffffff;
    }
  }
  /* SF styles*/

  fieldset {
    background: #ffffff;
    margin: 0 0 10px;
    padding: 0 15px 15px;
    position: relative;
  }
  .scfSingleLineGeneralPanel,
  .scfRadioButtonListGeneralPanel,
  .scfEmailGeneralPanel {
    display: block;
    float: left;
    margin: 0px 0px 1px 2px;
  }

  .scfSingleLineTextLabel,
  .scfRadioButtonListLabel,
  .scfEmailLabel {
    display: block;
    float: left;
    padding: 3px 0;
    width: 40%;
  }

  .formpanel {
    background-color: #fff;
    padding: 10px 5px 10px 5px;
  }
}

label.error {
  color: red;
  display: block;
  font-style: italic;
  font-weight: normal;
  text-align: right;
  float: right;
}

.Brookeformv2 {
}

/*-----------------------debuggin ----------------------------*/

.border {
  border: 1px solid #000;
}
