/*  scss file  */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $brooke-gray;
  opacity: 1; /* Firefox */
}

#searchForm {
  padding: 0px 20px 0 0;
  margin-top: 20px;
  // float: right;
  // width: 200px;
  text-align: right;

  @include breakpoint(medium) {
    margin: 0;
  }

  input[type="text"] {
    border: 1px solid $brooke-oranje;
    float: right;
    font-size: rem-calc(14);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3) inset;
    transition: all 0.7s ease 0s;
    width: 100px;
  }

  input:focus {
    width: 140%;
  }

  .searchbutton {
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
  }
}
