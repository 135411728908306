/*  scss file  */

.alt-row {
    background: url(/assets/img/layout/blockdividerBg.png) center top no-repeat;
    padding-top: 30px;
    margin-top: rem-calc(20);
    }

img.imagerechts, img.imgRight {
    width: 100%;

    @include breakpoint(medium) {
        float: right;
        width: auto;
        }

    }

img.imagelinks, img.imgLeft {
    width: 100%;

    @include breakpoint(medium) {
        float: left;
        width: auto;
        }

    }

.list {
    ul {
        margin: 0;
        }
    li {
        margin: 8px 0 0 0;
        list-style: none;
        font-size: 1rem;
        line-height: 1.5rem;
        width: 100%;
        }
    .count {
        font-size: rem-calc(12);
        color: $brooke-gray;
        }

    }