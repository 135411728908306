/*  scss file  */

.footer {
  background-color: $lighter-gray;
  padding: 15px 0 10px 0;
}

.disclaimer {
  color: $dark-gray;
  font-size: 9px;
  line-height: 1.2em;
  margin: 50px 0 0 0;
  padding: 10px 0 0 0;
}
