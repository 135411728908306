/*  scss file  */

.faq {

    .question {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        }
    .answer{
        color: $brooke-broodzwart;
        }

    }