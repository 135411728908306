/*  scss file  */

.blog-post, .blog-index {

    .post-info {
        ul {
            margin-left: 0;
            }

        li {
            list-style: none;
            margin-left: 0;
            }
        }

    .tl-tag {
        a {
            display: inline;
            }
        .count {
            font-size: rem-calc(12);
            color: $brooke-gray;
            }
        }
    }

.blog-index {

    .post {
        border-bottom: 1px solid $brooke-gray-dark;
        margin: 0 0 25px 0;
        padding: 0 0 25px 0;
        overflow: auto;

        a {
            color: $brooke-broodzwart;
            &:hover {
                color: $brooke-gray-dark;
                }
            }

        .title {
            margin: 0;
            padding: 0;
            a {
                color: $brooke-oranje;
                }
            }
        .auteur {
            font-size: rem-calc(12);
            }
        .entry {
            padding: 10px 0 0 0;
            }
        .post-info {
            margin: 0px 0 0 0;
            color: $brooke-gray-dark;
            }
        }

    }