.card {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100%;

  img {
    object-fit: cover;
    aspect-ratio: 16/9;
    border-radius: 4px 4px 0 0;
    width: 100%;
  }

  &__row {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0 8px;

    @include breakpoint(medium) {
      flex-wrap: nowrap;
      flex-direction: row;
      padding: 0 8px;
    }

    & > * {
      flex: 1;
    }
  }

  &__title {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
  }

  &__content {
    padding: 0.5rem 1rem;
    color: #000;
  }
}
