/*  scss file  */

.sm-share{
    font-size: rem-calc(30);
    padding-bottom: rem-calc(30);

    a{
        margin:rem-calc(6)
        }
    }

.smicons {
    padding-bottom:20px;
    }

#smicons {
    margin:0 0 20px 0;
    padding: 10px;
    text-align: right;
    a{
        padding:0 2px 0 0;
    }
}