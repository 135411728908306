/*  scss file  */

$btaBorderWidth : 7;
.cta {
    font-size: rem-calc(24);
    font-weight: 700;
    font-family: 'Open Sans Condensed';
    border-radius: 0px;
    border: 1px solid White;
    position: relative;
    background: linear-gradient($brooke-oranje-highlight, $brooke-oranje);
    transition: background 0.3s;
    min-width:100px;
    padding: 0.55em 1em;

    &:hover{
        background:  $brooke-oranje;
        }

    &:after {
        content: '';
        position: absolute;
        top: #{-$btaBorderWidth}px;
        left: #{-$btaBorderWidth}px;
        right: #{-$btaBorderWidth}px;
        bottom: #{-$btaBorderWidth }px;
        background: $brooke-oranje-highlight;
        z-index: -1;
        border-radius: 0px;
        }
    }