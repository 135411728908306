/*  scss file  */

#nieuws {
    padding: 10px;

    ul{
        padding:0;
        margin:0;

        }

    li {
        list-style: none;
        margin: 0 0 10px 0;
        line-height: 1.5em;
        a {
            color: #333;
            text-decoration: none;
            }
        }

    .datum {
        color: #da7f3d;
        display: block;
        }
    }

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    }