/*  scss file  */

.home {

    .homecol {
       // border: 1px solid $brooke-oranje;
        padding: 10px;
        text-align: justify;

        .imagewrapper {
            height: 207px;
            margin: 0 0 10px;
            overflow: hidden;
            //width: 33%;
            }
        }
    }