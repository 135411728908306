/*  scss file  */

.hero {
  &-unit {
    background-color: #f7f7f7;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: flex-end;
    width: 100%;

    @include breakpoint(medium) {
      flex-direction: column;
      height: 228px;
      justify-content: space-between;
    }
  }
}
