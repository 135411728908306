/*  scss file  */

#warningDiv {
    }

#formcontainer {

    legend {
        background-color: $brooke-gray-light;
        width: 100%;
        padding: 4px;
        }

    .panel {
        margin-bottom: 30px;
        }
    input[type='text'] {
        margin: 0;
        }

    .fieldrow {
        margin: 2px 0 rem-calc(16);
        }

    .periodiek-panel {
        }

    .error {
        border: 1px solid $brooke-rood;
        }
    label{
        background-color: transparent;
        color:$black;
    }
    .label{
        background-color: transparent;
        color:$black;
        font-size: 1rem;
    }
    input[type='checkbox']{
        margin:0 0.5rem 0 0;
    }

    label.error {
        border: none;
        color: $brooke-oranje;
        font-style: italic;
        }

    .verplicht {
        label {
            &:after {
                content: ' *';
                font-size: rem-calc(11);
                }
            }
        }

    //.optioneel {
    //    label {
    //        &:after {
    //            content: ' \00a0 \00a0 \00a0      (optioneel)';
    //            font-size: rem-calc(11);
    //            }
    //        }
    //    }

    }
