/*  scss file  */

//@font-face {
//	font-family: 'MuseoSans';
//	src: url('/assets/fonts/museo/museoSans_500-webfont.eot');
//	src: url('/assets/fonts/museo/museoSans_500-webfont.eot') format('embedded-opentype')
//	, url('/assets/fonts/museo/museoSans_500-webfont.woff') format('woff')
//	, url('/assets/fonts/museo/museoSans_500-webfont.ttf') format('truetype')
//	, url('/assets/fonts/museo/museoSans_500-webfont.svg#MuseoSans500') format('svg');
//	font-weight: normal;
//	font-style: normal;
//}

@import url("https://use.typekit.net/vfq0kbc.css");

.nobr { white-space:nowrap; }
.imgLeft{
    float: left;
    padding:15px 15px 15px 0;
    }

.imgRight{
    float: right;
    padding:15px 0 15px 15px;
    }