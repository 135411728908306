/*  scss file  */

.sidebar {

    & > #navigation {
        margin: 30px 0 0 0;
        }

    ul {
        list-style: none;
        margin: 0;
        }

    li {
        list-style: none;
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        margin-top: 8px;
        width: 100%;

        a {
            padding: 6px;
            padding-top: 2px;
            padding-bottom: 4px;
            margin-top: 2px;
            margin-bottom: 2px;
            border-radius: 5px;
            display: block;

            &.active {
                border: 1px solid $light-gray;
                }

            &:hover {
                background-color: $light-gray;
                }
            }
        }

    }