/*  scss file  */

.doneerBtn {
  float: none;
  position: relative;
  margin: 0;
  padding-top: 0px;
  max-width: 100%;
  min-height: 40px;
  height: 55px;
  border: white solid 0px;
  background-image: linear-gradient($brooke-oranje-highlight, $brooke-oranje);

  @include breakpoint(medium) {
    box-sizing: border-box;
    float: right;
    margin: 15px 35px 0 0;
    display: block;
    border: white solid 4px;
    min-width: 164px;
    min-height: 164px;
    max-width: 250px;
    text-align: center;
    background-image: radial-gradient(circle, $brooke-oranje-highlight, $brooke-oranje);
  }

  a {
    @include clearfix();
    width: 100%;
    text-align: center;
  }

  .innerDoneerBtn {
    position: relative;
    background: url(/assets/img/doneerBtn-inner-mobile.png) no-repeat bottom center;
    height: 40px;
    margin: 0 0 0 0;
    display: block;

    @include breakpoint(medium) {
      background: url(/assets/img/doneerBtn-inner.png) no-repeat bottom center;
      height: 140px;
      display: block;
      margin: -13px 0 0 0;
    }
  }

  .juniorInnerDoneerBtn {
    @include clearfix();
    background: url(/assets/img/doneerBtn-junior-inner.png) no-repeat bottom center;
    height: 140px;
    display: block;
    @media (max-width: 767px) {
      background-image: url(/assets/img/doneerBtn-junior-inner-mobile.png);
      height: 50px;
      margin: 3px 0 0 0;
    }
  }
}
