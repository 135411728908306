.drilldown {
  a {
    color: $brooke-broodwit;
    border-bottom: 1px solid $brooke-oranje-highlight;
  }
}

.close-button {
  color: #fff;
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: lighter;
}
