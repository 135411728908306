.topbar {
  width: 100%;
  background: transparent;
  padding: 5px;
  color: $brooke-broodwit;

  @include breakpoint(medium) {
    padding: 0px;
  }

  &-container {
    position: fixed;
    background-color: $brooke-oranje;
    background-image: linear-gradient(to bottom, $brooke-lightgrad, $brooke-darkgrad);
    z-index: 1;
  }

  a {
    font-family: $header-font-family;
  }

  ul {
    li {
      a {
        color: $brooke-broodwit;
        font-size: rem-calc(16);
        font-weight: normal;
        cursor: pointer;
        // text-shadow: 0 1px 0 #fff;
      }
    }
  }

  .is-active > a {
    background-color: $brooke-darkoranje;
    color: #555;
    text-shadow: 0 1px 0 #fcac49;
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  }

  .toplevel > li {
    color: $brooke-broodzwart;
    background: url(/assets/img/layout/nabar-divider.png) right center no-repeat;

    & > a {
      color: $brooke-broodzwart;
      text-transform: uppercase;
      font-size: rem-calc(16);

      @include breakpoint(medium only) {
        font-size: rem-calc(14);
      }
    }
  }

  .dropdown-menu {
    a {
      color: $brooke-broodzwart;
    }
  }

  .dropdown.menu {
    .submenu {
      border: 1px solid #999;
      background-color: #fff;
      border-radius: 8px;
      background-color: #fff;

      a {
        color: $brooke-broodzwart;
        font-weight: normal;
        padding: 0.2rem 0.7rem;
        line-height: 1.5;

        &:hover {
          background-color: $brooke-darkoranje;
          color: $brooke-broodwit;
        }
      }
    }
  }

  // Arrow styling
  .dropdown.menu > li.is-dropdown-submenu-parent > a:after {
    border-color: $brooke-broodwit transparent transparent;
  }

  .is-drilldown-submenu-parent > a::after,
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a:after {
    border-color: transparent transparent transparent $brooke-broodwit;
  }

  .js-drilldown-back > a:before,
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a:after {
    border-color: transparent $brooke-darkoranje transparent transparent;
  }

  &-spacer {
    min-height: 55px;

    @include breakpoint(medium) {
      min-height: 35px;
    }
  }
}
